import Seo from 'src/components/seo/Seo'

import CartaoDecathlon from '../components/DecathlonItau'

function DecathlonItau() {
  return (
    <>
      <Seo title="Cartão Decathlon | Decathlon" />
      <CartaoDecathlon />
    </>
  )
}

export default DecathlonItau
